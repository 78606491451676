body{
	background-color: #edf0f4;
	font-family: "Helvetica", sans-serif;
	color: #fff;
}
.toggleWrapper{
	/* position: absolute;
	top: 50%;
	left: 50%; */
	/* overflow: hidden; */
	/* -webkit-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0); */
}
.toggleWrapper input{
	position: absolute;
	left: -99em;
}
.toggle{
	cursor: pointer;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 26px;
	background: #e0757e;
	border-radius: 5px;
	-webkit-transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
	transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.toggle:before, .toggle:after{
	position: absolute;
	line-height: 25px;
	font-size: 10px;
	z-index: 2;
	-webkit-transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
	transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.toggle:before{
	content: "停止";
	left: 12px;
	color: #e0757e;
}
.toggle:after{
	content: "挖掘";
	right: 10px;
	color: #fff;
}
.toggle__handler{
	display: inline-block;
	position: relative;
	z-index: 1;
	background: #fff;
	width: 40px;
	height: 20px;
	border-radius: 3px;
	top: 3px;
	left: 3px;
	-webkit-transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
	transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
	-webkit-transform: translateX(0px);
	transform: translateX(0px);
}
input:checked + .toggle{
	background: #89bd53;
}
input:checked + .toggle:before{
	color: #fff;
}
input:checked + .toggle:after{
	color: #89bd53;
}
input:checked + .toggle .toggle__handler{
	width: 35px;
	-webkit-transform: translateX(40px);
	transform: translateX(40px);
	border-color: #fff;
}
